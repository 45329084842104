import styled from 'styled-components'
import React from 'react'
import { NavLink } from 'react-router-dom'
import colors from "../../utils/style/color"
import '../../utils/style/AnimationTurnComponent.css'

// Global flex container
const FlexContainerFooterDiv = styled.div`

    padding: 1vw 1vw;
    position:relative;
    display: flex;
    flex-direction: column;
    background-color: ${colors.FooterBackground};

`

// container with practical information, logo and social media link
const FooterLinksDIV = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    text-align: left;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;

`

// flexbox to contain practical information, logo and social media link
const FooterLinksDIVDIV = styled.div`
min-width: 15vw;
margin:1vw;
display: flex;
justify-content: flex-start;
flex-direction: column;
color: white;


& a { color: white;text-decoration: none; }

& h1 { font-size: 14px;margin-bottom:0.9rem;
    @media (min-width: 1800px){
        font-size: 30px;
    }
    @media (min-width: 2800px){
        font-size: 60px;
    }
}

& p { font-size: 12px;margin:0.1rem 0;cursor:pointeur; 
    @media (min-width: 1800px){
        font-size: 16px;
    }
    @media (min-width: 2800px){
        font-size: 32px;
    }
}

@media (max-width: 704px){
            margin: 1rem 0rem;
        }
`


const FooterLinksDIVDIVCenter = styled(FooterLinksDIVDIV)`
    align-items: center;
    margin: 1rem 4rem;
    width: 100%; /* Use 100% width to fill the entire container */
    max-width: 25rem; /* Set a maximum width to prevent it from becoming too wide */
    align-self: center;

    & p {
    @media (min-width: 1800px){
        font-size: 20px;
    }
    @media (min-width: 2800px){
        font-size: 40px;
    }
}

`

const FooterLinksDIVDIVSocial = styled(FooterLinksDIVDIV)`
    align-items: center;
    min-width: 15vw;
`       


const FooterTxtAndLogoDIV = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    cursor: pointer;

    &:hover{
            color:rgb(180,180,180);
        }

    & img {height:1.25rem ; width:1.25rem}
`

const SocialMediaDIV = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ALinkedin = styled.a`

    color: rgb(150, 150, 150);
    transition : color 0.2s;

    &:hover{
        color:#087BB9;
    }
`

// container flex with all right reserved, privacy...etc
const FooterBelowDIV = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.2rem;

    @media (max-width: 704px){
                flex-direction: column;
                justify-content: left;
            }

`

// individual flexbof for privacy, legal mention...etc
const FooterBelowLinkDIV = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    margin-right: 1rem;
    font-size: 0.75rem;

    & p { font-size:13px; line-height: 15px; margin-left: 2rem; color:white; font-weight:400; }

    & hr { color: white !important; width:100%; }

    @media (max-width: 704px){
                margin-left: 1rem;
                margin-top: 1rem;
            }
`

// individual flexbof for privacy, legal mention...etc
const FooterCopyrightDIV = styled.div`
    line-height: 15px;
    color: white;
    font-weight: 400;
    margin-bottom: 1rem;
    margin-left: 1rem;
    cursor: pointer;

    &:hover{
            color:rgb(180,180,180);
        }
`

const StyledHashLink = styled(NavLink)`
    font-size: 0.4rem;
    text-decoration: none;
    color:white;
    font-size: 0.8rem;

    &:hover{
        color:rgb(180,180,180);
    }

    @media (max-width: 810px){
        font-size: 0.6rem;
            }
            
    @media (min-width: 1800px){
        font-size: 1rem;
            }
            @media (min-width: 2800px){
        font-size: 2rem;
            }
`

const Span = styled.span`

    width: 50px;
    height: 3px;
    border: none;
    border-radius: 3px;
    margin: 0 1rem ;
    box-shadow: 0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #fff,inset 0 0 16px #fff,0 0 32px #fff,inset 0 0 32px #fff;
`

const A = styled.a`

    text-decoration: none;
    font-size: 12px;
    &:hover{
            color:rgb(180,180,180);
        }
        @media (min-width: 1800px){
        font-size: 16px;
    }
    @media (min-width: 2800px){
        font-size: 32px;
    }

`

const P = styled.p`

    text-align: center;
    font-size: 0.4rem;
    text-decoration: none;
    color:white;
    font-size: 0.8rem;

    &:hover{
        color:rgb(180,180,180);
    }

    @media (max-width: 810px){
        font-size: 0.6rem;
            }
            
    @media (min-width: 1800px){
        font-size: 1rem;
            }
            @media (min-width: 2800px){
        font-size: 2rem;
            }



`

function Footer() {

    return (
        <FlexContainerFooterDiv>

            <FooterLinksDIV>

                {/* 1 */}
                <FooterLinksDIVDIV>

                    <FooterTxtAndLogoDIV>
                    <img src={'/data/images/logo/footer/AdressLogo.webp' } alt="logo of adress" style={{paddingRight: "0.5rem"}}></img>
                    <p>Sebadev, Montpellier, 34000, France.</p>
                    </FooterTxtAndLogoDIV>

                    <FooterTxtAndLogoDIV>
                    <img src={'/data/images/logo/footer/TelLogo.webp' } alt="logo of tel" style={{paddingRight: "0.5rem"}}></img>
                    <p>06.10.97.10.19</p>
                    </FooterTxtAndLogoDIV>

                    <FooterTxtAndLogoDIV>
                    <img src={'/data/images/logo/footer/MailLogo.webp'} alt="logo of email" style={{paddingRight: "0.5rem"}}></img>
                    <A href="mailto:sebastienpetit.dev@gmail.com">sebastienpetit.dev@gmail.com</A>
                    </FooterTxtAndLogoDIV>
                </FooterLinksDIVDIV>

                {/* 2 */}
                <FooterLinksDIVDIVCenter>
                    <p style={{textAlign: "justify"}}><b>Développeur web Front-end et Backend avec un statut d'ingénieur roboticien</b>, je suis un couteau suisse du développement numérique, passionné par ces technologies, je propose mes services en adéquation avec vos besoins.</p>
                    <img onMouseEnter={(e) => e.currentTarget.classList.add('rotate360Class') } onAnimationEnd={(e) => e.currentTarget.classList.remove('rotate360Class')} src={"/data/images/logo/header/logo.webp"} alt="logo of app" style={{width:"75px", height:"75px", margin:"1rem 0rem"}}/>
                    <p style={{textAlign: "justify", marginBottom:"0.5rem"}}>Je suis disponible pour la réalisation de vos <b>projets web sur-mesure</b>, de la création de sites internet jusqu'aux applications web plus complexe.</p>
                    <p style={{textAlign: "justify"}}>Fort d'une formation en <b>ingénierie robotique</b> et de <b>plusieurs années d'expérience professionnelle</b>, je propose mon expertise sur une gamme variée de sujets.</p>
                </FooterLinksDIVDIVCenter>

                {/* 3 */}
                <FooterLinksDIVDIVSocial>
                        
                    <SocialMediaDIV>

                        <ALinkedin style={{margin:"1rem"}} href="https://www.linkedin.com/in/s%C3%A9bastien-petit-rob/" target="_blank" rel="noreferrer">  <i className="fa-brands fa-linkedin-in fa-2xl"></i> </ALinkedin>
                        {/* <AThreads style={{marginRight:"1rem"}} href="https://www.threads.net/@sebastiengoodspeed" target="_blank" rel="noreferrer">  <i className="fa-brands fa-threads fa-2xl"></i> </AThreads> */}
                        {/* <AInsta href="https://www.instagram.com/sebastiengoodspeed/" target="_blank" rel="noreferrer">  <i className="fa-brands fa-instagram fa-2xl"></i> </AInsta> */}
                       
                    </SocialMediaDIV>

                </FooterLinksDIVDIVSocial>

            </FooterLinksDIV>

            <FooterBelowDIV>

                <FooterCopyrightDIV>
                <P> © {new Date().getFullYear()} SebaDev. All right reserved. </P>
                </FooterCopyrightDIV>

                <FooterBelowLinkDIV>
                    <StyledHashLink  to="/mentions-legales/#mentions">Mentions légales et RGPD</StyledHashLink>
                    <Span></Span>
                    <StyledHashLink to="https://sebastien-petit-dev.com/">Site internet réalisé par Sébastien Petit</StyledHashLink>
                </FooterBelowLinkDIV>

            </FooterBelowDIV>
        

        </FlexContainerFooterDiv>
    )
}
 
export default Footer